import { Vue, Component } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import API_CONST from '@/constants/ApiConst';



@Component
export default class SocialStuffComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public url = API_CONST.THOUGHTINDUSTRYURL;
    public label_text = ["Dashboard", 'Scholar Management - Recruitment, Enrollment and Attendance']
    public navigatePlannerPage: boolean = false;
  
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }
}